<template>
  <div>
    <vx-card actionable class="cardx" title="MWB Reference">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="10">
          <treeselect
            v-model="initSelectedBDE"
            :multiple="true"
            :options="treeDataBDE"
            style="width: 300px; margin-right: 3%"
          />
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 240px; margin-right: 3%"
            @update="analyzePagination(1)"
          ></date-range-picker>
          <v-select
            :filterable="false"
            :options="mwb_names"
            @search="autoCompleteName"
            v-model="value1"
            placeholder="Name"
            style="width: 240px; z-index: 1000"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
          <vs-button
            radius
            color="dark"
            type="gradient"
            @click="analyzePagination(1)"
            icon="search"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshnew"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col>
          <leads-data-table :totalpagination="tablelinks" />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../components/pagesComponents/LeadsNavBar.vue";
import LeadsDataTable from "../components/pagesComponents/LeadsDataTableMHP.vue";
import FocusSearch from "../components/pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../components/pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    LeadsNavBar,
    LeadsDataTable,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    Treeselect,
    "v-select": vSelect,
  },
  data() {
    return {
      selecteddatebool: false,
      lang: {
        placeholder: {
          date: "Next Contact",
          dateRange: "Select a date range",
        },
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      nameAutocomplete: [],
      initSelected: "",
      initSelectedBDE: [],
      initSelectedCourses: [],
      initSelectedLevels: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      countsleads: 0,
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA"
            },
            {
              id: "CPA-US",
              label: "CPA-US"
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            
            // {
            //   id: "RPO",
            //   label: "RPO"
            // },
            {
              id: "IIML-FA",
              label: "IIML-FA",
            },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "IIML-SF",
              label: "IIML-SF",
            },
            {
              id: "IIML-DA",
              label: "IIML-DA",
            },
            {
              id: "IIML-HR",
              label: "IIML-HR",
            },
            {
              id: "IITR-BF",
              label: "IITR-BF",
            },
            { id: "IITR-DB", label: "IITR-DB" },
            { id: "IITM-AA", label: "IITM-AA" },
            { id: "IIMK-CX", label: "IIMK-CX" },
            { id: "IITM-FS", label: "IITM-FS" },
            { id: "IITR-CC", label: "IITR-CC" },
            { id: "IIMK-FT", label: "IIMK-FT" },
            { id: "IIML-AB", label: "IIML-AB" },
            { id: "IIML-SH", label: "IIML-SH" },
            { id: "IITJ-DE", label: "IITJ-DE" },
            { id: "XLRI-HR", label: "XLRI-HR" },
            { id: "XLRI-SH", label: "XLRI-SH" },
            { id: "IIMI-BA", label: "IIMI-BA" },
            { id: "IIMI-AA", label: "IIMI-AA" },
            { id: "XLRI-DM", label: "XLRI-DM" },
            {
              id: "IIML-PM",
              label: "IIML-PM",
            },
          ],
        },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M5",
                  label: "M5",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2",
                },
                {
                  id: "L3",
                  label: "L3",
                },
                {
                  id: "L4",
                  label: "L4",
                },
                {
                  id: "L6",
                  label: "L6",
                },
                {
                  id: "L5",
                  label: "L5",
                },
                {
                  id: "L3+",
                  label: "L3+",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "L4-",
                  label: "L4-",
                },
                {
                  id: "L1",
                  label: "L1",
                },
              ],
            },
            {
              id: "P4",
              label: "P4",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
              ],
            },
          ],
        },
      ],
      tablelinks: 0,
      currentTablePage: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      infosearch: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0,
      mwb_names: [],
      rawcities: [],
    };
  },
  beforeDestroy() {
    EventBus.$emit("MWBtitlehide");
  },
  mounted() {
    if (localStorage.getItem("role") === "") {
      localStorage.setItem("role", "cm1");
    }
    this.cm2Redirect();
    this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "wc" ||
      localStorage.getItem("role") === "ac2" ||
      localStorage.getItem("role") === "fe" ||
      localStorage.getItem("role") === "cmca" ||
      localStorage.getItem("role") === "baca" ||
      localStorage.getItem("role") === "hr"
    ) {
      this.getSpocAdmin();
    } else {
      this.getSpoc();
    }
    EventBus.$emit("MWBtitleshow", "REFERENCE");
    EventBus.$on("radio-change", (val) => {
      // console.log("got the radio change event");
      this.initSelected = val;
    });
    EventBus.$on("open-email-leadsdatatable", (payload) => {
      this.bulkdata = [];
      for (let y = 1; y <= 5; y++) {
        this.search_API(y, true, payload);
        // console.log("before");
      }
      // console.log(payload);
      // console.log(this.bulkdata);
      this.bulkdata.forEach((bulk) => {
        console.log(bulk);
      });
    });
  },
  watch: {
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function () {
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    currentTablePage: async function (val) {
      this.analyzePagination(val);
    },
    infosearch: function () {
      this.mergeAllSearch();
    },
  },
  methods: {
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          vm.mwb_names = response.data.map((lead) => lead.person_name);
          loading(false);
        });
    }, 350),
    openEmail() {
      this.EmitEvent("open-email-bdeleads");
    },
    removechip(chip) {
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      this.treeDataBDE[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "bde";
        }
      });
      this.treeDataCourses[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach((child) => {
        child.children.forEach((chi) => {
          if (chi.label === chip) {
            chipPlacement = "levels";
          }
        });
      });
      // console.log(chipPlacement)
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            this.initSelectedBDE = [];
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedBDE.push(child.label);
              }
            });
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach((child) => {
              child.children.forEach((chi) => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach((child) => {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach((child) => {
              if (child.label === childProperty) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                  }
                });
              }
            });
          }
      }
    },
    analyzePagination(val) {
      if (this.currentTablePage === 1) {
        this.search_API(1);
      } else {
        this.search_API(val);
      }
    },
    daterageclear() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
    },
    refreshnew() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.value1 = "";
      this.initSelectedBDE = [];
      if (this.rawBde.indexOf(this.searchIn) !== -1) {
        this.initSelectedBDE.push(this.searchIn.name);
      } else {
        this.initSelectedBDE.push("All");
      }
      this.analyzePagination(1);
    },
    async search_API(page, copyfor) {
      // console.log(this.value1);
      let focus = this.initSelected;
      let levels = [];
      let bde = [];
      let courses = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      // console.log(bde);
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: focus,
        spoc_ids: bdeid.join(),
        name: this.value1,
        company: this.value2,
        mobile: this.value3,
        email: this.value4,
        visitors: "",
        net_enquiry: "",
        eligibility: "",
        reference: "Yes",
        loan: "",
        corporate: "",
        university: "",
        from_date: "",
        to_date: "",
      };
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      console.log(obj);
      if (copyfor === true) {
        axios
          .post(`${constants.SERVER_API}getSearchedMwbLeads?page=${page}`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);

            response.data.data.forEach((lead) => {
              if (this.bulkdata.length !== 50) {
                this.bulkdata.push(lead);
              }
            });
          })
          .catch((error) => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          });
      } else {
        // console.log(obj);
        axios
          .post(`${constants.SERVER_API}getSearchedMwbLeads?page=${page}`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            this.countsleads = response.data.total;
            // console.log(countsleads);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          })
          .catch((error) => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          });
      }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.value1 = "";
      this.value2 = "";
      this.infosearch = [];
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.getSpocAdmin();
      } else {
        this.getSpoc();
      }
    },
    getSpoc() {
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;

      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (
        localStorage.getItem("role") === "exed" ||
        localStorage.getItem("role") === "exed2"
      ) {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.rawBde = [];
          let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.searchIn.id) {
              usercity = spoc.city;
            }
          });
          response.data.spocs.forEach((spoc) => {
            // if (spoc.city === usercity) {
            //   this.rawBde.push(spoc);
            // }
            if (this.getrole("cm1")) {
              if (spoc.city === usercity) {
                this.rawBde.push(spoc);
              }
            } else if (this.getrole("exed") || this.getrole("exed2")) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.full_name,
              label: data.full_name,
            };
            this.treeDataBDE[0].children.push(obj);
          });
          if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "exed" ||
            localStorage.getItem("role") === "exed2"
          ) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          } else {
            this.initSelectedBDE.push("All");
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "") {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    search() {
      this.search_API();
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      console.log("clicked");
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise((resolve) => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" },
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    },
    consolelog() {
      console.log("clicked");
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
  },
};
</script>

<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
</style>
